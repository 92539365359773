<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HttpRequest from "@/http/HttpRequest"
import Preference from "@/data/Preference";
import Constants from "@/data/Constants";
import Utils from "@/helpers/Utils";


let httpRequest = new HttpRequest()
let preference = new Preference()

export default {

  data() {
    return {
      appName:'',
      title: "Reports",
      items: [
        {
          text: "WORKANY",
          to:'/'
        },
        {
          text: "Report",
          active: true
        }
      ],
      isLoading: false,
      isLoadingMembers: false,
      projectListSelectOption: [],
      projectIdList: [],
      memberListSelectOption: [],
      selectedMemberId: null,
      fromDate: null,
      toDate: null,
      datePickerPlaceholder: 'Today',
      activitiesData: [],
      all: {
        id: null,
        name: 'All'
      },
      allByDates: {
        id: null,
        name: 'All by Dates'
      },
      allByMember: {
        id: 'all-by-members',
        name: 'All by Members'
      },
      me: {
        id: null,
        name: 'Me'
      },
      selectedProjectId: null,
      isOwnerOfTheSelectedProject: false,
      reportTableData: [],
      reportTableFields: [
      //  {key: "user_name", sortable: true, label: "Name"},
       // {key: "email", sortable: true, label: "Email"},
        {key: "date", sortable: false, label: "Date"},
        {key: "total_time", sortable: false, label: "Total Time"},
        {key: "idle_time", sortable: false, label: "Thinking Time"},
        {key: "active_time", sortable: false, label: "Executing Time"},
        {key: "percentage", sortable: false, label: "Percentage"},
      ],
    }
  },

  components: {
    Layout,
    PageHeader
  },

  methods: {
    getProjectList() {
      let _this = this
      _this.isLoading = true
      return httpRequest.getProjectList().then(function (response) {
        _this.isLoading = false
        if (response.success) {
          response.data.forEach(function (projectData) {
            if(projectData.project.project_status == 1){
              let project = {
                id: projectData.project.project_id,
                name: projectData.project.project_name
              }
              _this.projectListSelectOption.push(project)
              if (projectData.role_id === Constants.ROLE_PRODUCT_OWNER) {
                _this.projectIdList.push(projectData.project.project_id)
              }
            }
          })
        }
      })
    }, getProjectMemberList() {
      let _this = this
      _this.memberListSelectOption = []
      if (_this.selectedProjectId != null) {
        _this.isLoadingMembers = true
        httpRequest.getProjectMembers(_this.selectedProjectId).then(function (response) {
          _this.isLoadingMembers = false
          if (response.success) {
            let myUserId = preference.getData(Preference.KEY_USER_ID)
            _this.isOwnerOfTheSelectedProject = false
            response.data.forEach(function (member) {

              let myRoleId = -1
              if (member.user !== null) {
                if (member.user.user_id === myUserId) {
                  _this.selectedMemberId = member.user.user_id
                  myRoleId = member.role_id
                  _this.isOwnerOfTheSelectedProject = (myRoleId === Constants.ROLE_PRODUCT_OWNER);
                }
                let user = {
                  id: member.user.user_id,
                  name: member.user.name
                }
                _this.memberListSelectOption.push(user)
              }
            })
            _this.memberListSelectOption.push(_this.allByDates)
            _this.memberListSelectOption.push(_this.allByMember)

            if (!_this.isOwnerOfTheSelectedProject) {
              _this.memberListSelectOption = []
              let user = {
                id: _this.selectedMemberId,
                name: preference.getData(Preference.KEY_NAME)
              }
              _this.memberListSelectOption.push(user)
            }
          }
        })
      } else {
        _this.getAllMembersBasedOnProjectList()
      }
    },
    getAllMembersBasedOnProjectList() {
      let _this = this
      _this.memberListSelectOption = []
      if (_this.projectIdList.length > 0) {
        _this.isLoadingMembers = true
        httpRequest.getProjectMembers(_this.projectIdList).then(function (response) {
          _this.isLoadingMembers = false
          if (response.success) {
            _this.isOwnerOfTheSelectedProject = false
            response.data.forEach(function (member) {
              if (member.user !== null) {
                let user = {
                  id: member.user.user_id,
                  name: member.user.name
                }
                _this.memberListSelectOption.push(user)
              }
            })
            // remove duplicate if exist
            if (_this.memberListSelectOption.length > 0) {
              const key = 'id';
              _this.memberListSelectOption = [...new Map(_this.memberListSelectOption.map(item =>
                  [item[key], item])).values()];
            } else {
              _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID)
              _this.me.id = _this.selectedMemberId
              _this.me.name = preference.getData(Preference.KEY_NAME)
              _this.memberListSelectOption.push(this.me)
            }
          }
        })
      } else {
        _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID)
        _this.me.id = _this.selectedMemberId
        _this.me.name = preference.getData(Preference.KEY_NAME)
        _this.memberListSelectOption.push(this.me)
      }
    },
    loadSelectedData() {
      let _this = this
      let fromDate = Utils.formatDate(this.fromDate)
      let toDate = Utils.formatDate(this.toDate)
      _this.isLoading = true
      let timezone = Utils.getMyTimezone();
      if(_this.selectedMemberId == 'all-by-members')
      {
        //This one for All member of a project
        _this.reportTableFields = [
          {key: "user_name", sortable: false, label: "Name"},
          {key: "email", sortable: false, label: "Email"},
          // {key: "date", sortable: false, label: "Date"},
          {key: "total_time", sortable: false, label: "Total Time"},
          {key: "idle_time", sortable: false, label: "Thinking Time"},
          {key: "active_time", sortable: false, label: "Executing Time"},
          {key: "percentage", sortable: false, label: "Percentage"},
        ]
      }
      else{
        //This one for single member of any project
        _this.reportTableFields = [
          {key: "date", sortable: false, label: "Date"},
          {key: "total_time", sortable: false, label: "Total Time"},
          {key: "idle_time", sortable: false, label: "Thinking Time"},
          {key: "active_time", sortable: false, label: "Executing Time"},
          {key: "percentage", sortable: false, label: "Percentage"},
        ]

      }


      httpRequest.getReportPreparationData(fromDate, toDate, _this.selectedProjectId, _this.selectedMemberId, timezone).then(function (response) {
        _this.isLoading = false
        _this.reportTableData = []
        if (response.success) {
          if (response.data.length > 0) {
            let reportData
            let total_sum_time = 0
            let total_idle_time = 0
            let active_time = 0
            response.data.forEach(function (data) {
              total_sum_time = total_sum_time + parseInt(data.total_time)
              total_idle_time = total_idle_time + parseInt(data.idle_time)
              active_time = active_time + parseInt(data.active_time)
              reportData = {
                "user_name": data.name ,
                "email": data.email,
                "date": data.date,
                "total_time": Utils.formatTime(data.total_time),
                "idle_time": Utils.formatTime(data.idle_time),
                "active_time": Utils.formatTime((data.total_time - data.idle_time)),
                "percentage": Math.round(((data.total_time - data.idle_time) * 100) / data.total_time) + "%",
              }
              _this.reportTableData.push(reportData)

            })
            reportData = {
              "date": 'Total  ',
              "email": _this.selectedMemberId == 'all-by-members'?'Total ': '',
              "total_time": Utils.formatTime(total_sum_time.toString()),
              "idle_time": Utils.formatTime(total_idle_time.toString()),
              "active_time": Utils.formatTime((total_sum_time - total_idle_time)),
              "percentage":Math.round(((total_sum_time - total_idle_time) * 100) / total_sum_time) + "%",
            }
            _this.reportTableData.push(reportData)
          }
        }
      })
    },
    loadInitialData() {
      this.fromDate = Utils.getToday().replaceAll("/", "-")
      this.toDate = Utils.getToday().replaceAll("/", "-")
      this.projectListSelectOption.push(this.all)
      if (this.selectedMemberId == null) {
        this.selectedMemberId = preference.getData(Preference.KEY_USER_ID)
        this.me.id = this.selectedMemberId
        this.me.name = preference.getData(Preference.KEY_NAME)
      }
      this.memberListSelectOption.push(this.me)
      this.loadSelectedData()
    },
    exportSelectedData() {
      let _this = this
      let fileName = ''
      let user_name = ''
      this.memberListSelectOption.forEach(function (item) {
        if (item.id === _this.selectedMemberId) {
          let today = new Date();
          let time = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
          fileName = item.name + "_" + _this.fromDate + "_to_" + _this.toDate + "_" + time
          user_name = item.name
        }
      })
      if (this.reportTableData.length > 0) {
        Utils.convertToCsv(this.reportTableData, fileName, user_name)
      }
    },
    expandUrls(data) {
      data.expand = true
    }, getHostnameFromUrl(string) {
      if (string) {
        try {
          return new URL(string).hostname
        } catch (error) {
          return string
        }
      }
      return null
    },
    printData() {
      if (this.reportTableData.length > 0) {
        window.print()
      }
    },
  },
  async created() {
    await this.loadInitialData()
    await this.getProjectList()
    await this.getAllMembersBasedOnProjectList()
    this.appName = this.$route.query.app_name || 'DefaultAppName';
    console.log(this.appName);
  }

}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-body">
            <div class="row report-form-row">
              <div class="col-sm-6 col-md-6 col-xl-3">
                <label class="report-form-label">From</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <!-- Icon or content to prepend -->
                        <span class="input-group-text">
                          <i class="ri-calendar-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-datepicker
                          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                          id="from-datepicker"
                          v-model="fromDate"
                          placeholder="Today"
                      ></b-form-datepicker>
                    </b-input-group>
                  </div>
                </template>

              </div>
              <div class="col-sm-6 col-md-6 col-xl-3">
                <label class="report-form-label">To</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-calendar-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-datepicker
                          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                          id="to-datepicker"
                          v-model="toDate"
                          placeholder="Today"
                      ></b-form-datepicker>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-4 col-xl-2">
                <label class="report-form-label">Project</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                           <i class="ri-folder-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-select
                          :options="projectListSelectOption"
                          v-model="selectedProjectId"
                          value-field="id"
                          text-field="name"
                          v-on:change="getProjectMemberList"
                      ></b-form-select>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-4 col-xl-2">
                <label class="report-form-label">Member</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                           <i class="ri-user-3-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-select
                          :options="memberListSelectOption"
                          v-model="selectedMemberId"
                          value-field="id"
                          text-field="name"
                          :disabled="isLoadingMembers"
                      ></b-form-select>
                    </b-input-group>
                  </div>
                </template>

              </div>
              <div class="col-md-4 col-xl-2 report-search">
                <label class="report-form-label">Action</label>
                <b-button
                    block
                    variant="primary"
                    class="customs-bg-color"
                    v-on:click="loadSelectedData"
                ><i class="ri-eye-line" style="vertical-align:bottom"></i> VIEW
                </b-button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">

                <b-table
                    ref="projectTable"
                    show-empty
                    :items="reportTableData"
                    :striped="true"
                    responsive=""
                    :fields="reportTableFields"
                    :bordered="true"
                    :hover="true"
                    :dark="false"
                    :busy="isLoading"
                    id="reportTable"
                    caption-top>

                  <template #table-caption v-if="reportTableData.length>0">
                    <div class="alert alert-info text-center"
                         style="background: #fff;border: none; margin-top: -10px; position:relative;padding: 0"
                         role="alert" v-if="!isLoading">
                      <div class="alert alert-info- mt-3- pb-1 d-flex justify-content-between align-items-center  "
                           style="border: 1px solid #ced4da; padding: 5px 18px;">
                        <span class="" :style="{ color: 'black',fontSize:'15px', fontWeight:'700', marginLeft:'0px' }" >
                          {{memberListSelectOption.find(member => member.id === selectedMemberId)?.name}} - {{projectListSelectOption.find(project => project.id === selectedProjectId)?.name}} - {{fromDate}} to {{toDate}}
                        </span>
                        <div class="actions">
                          <i class="ri-file-download-line text-primary- cursor-pointer"
                             style="vertical-align:bottom; font-size: 22px; margin-right: 10px; cursor: pointer; color: #ff7c47"
                             v-on:click="exportSelectedData" v-b-tooltip.hover.top title="Download CSV">
                          </i>
                          <i class="ri-printer-line text-primary- cursor-pointer" style="vertical-align:bottom; font-size: 22px; cursor: pointer; color: #ff7c47"
                             v-on:click="printData" v-b-tooltip.hover.top title="Print"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </template>



                  <template v-for="field in reportTableFields" v-slot:[`head(${field.key})`]="{ label }">
                    <span class="customs-text-color"  v-bind:key="field.key">{{ label }}</span>
                  </template>

                  <template #cell(url_data)="row">
                    <div v-if="row.item.expand">
                      <ul>
                        <li v-for="(item, index) in row.item.url_data" :key="index">
                          <a target="_blank" :href="item.url"> {{ item.siteName }} </a>
                        </li>
                      </ul>
                    </div>
                    <div v-if="!row.item.expand && row.item.url_data.length > 0">
                      <b-button variant="link" v-on:click="expandUrls(row.item)">Visited
                        {{ row.item.url_data.length }} URL
                      </b-button>
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                </b-table>

              </div>
            </div>
            <div class="report-bottom text-right" v-if="reportTableData.length>0">
<!--              <b-button variant="primary" class="mr-2 customs-bg-color" v-on:click="exportSelectedData">-->
<!--                &lt;!&ndash;                <i class="ri-chat-download-line" style="vertical-align: middle;"></i> EXPORT&ndash;&gt;-->
<!--                <i class="ri-file-download-line" style="vertical-align:bottom"></i> EXPORT-->
<!--              </b-button>-->
<!--              <b-button variant="primary" v-on:click="printData" class="customs-bg-color">-->
<!--                <i class="ri-printer-line" style="vertical-align:bottom"></i> PRINT-->
<!--              </b-button>-->
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </Layout>
</template>

<style >

@media print {
  .card {
    font-size: 1rem;
  }

  .report-form-label,.actions {
    display: none;
    padding-top: 15px;
    margin-bottom: -10px;
  }

  .report-form-row > div {
    display: none;
    max-width: 50%;
    flex: 0 0 50%;
  }

  .report-form-row .form-control,
  .report-form-row .form-control label,
  .report-form-row .custom-select {
    /*border-width: 0;*/
    /*-webkit-appearance: none;*/
    /*appearance: none;*/
    /*padding-bottom: 0px;*/
    /*padding-left: 0px;*/
    /*padding-top: 0px;*/
    display: none !important;
  }

  .b-form-btn-label-control.form-control > #from-datepicker,
  .b-form-btn-label-control.form-control > #to-datepicker,
  .report-search,
  .report-bottom {
    display: none !important;
  }
}

.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}

#from-datepicker > svg{
  display: none;
}
#to-datepicker > svg{
  display: none;
}

#reportTable tr:last-child td {
  color: black;
  font-weight: bolder;
}
/*#reportTable tr:last-child td:first-child {*/
/*  color: black;*/
/*  font-weight: bolder;*/
/*  text-align: right;*/
/*}*/
</style>


